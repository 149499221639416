import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProjectsService } from 'src/app/services/projects.service';
import { MainService } from 'src/app/services/main.service';
import Swal from 'sweetalert2';
import { ProjectModel } from 'src/app/models/project.model';
import { ProjectExtraStoresAddFileComponent } from '../project-extra-stores-add-file/project-extra-stores-add-file.component';

@Component({
  selector: 'app-project-extra-stores-add',
  templateUrl: './project-extra-stores-add.component.html',
  styleUrls: ['./project-extra-stores-add.component.scss']
})
export class ProjectExtraStoresAddComponent implements OnInit {
  project: ProjectModel = new ProjectModel();
    isLoading = true;
    promotionFiles: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
        private projectService : ProjectsService,
        private main : MainService,
        private matDialog: MatDialog,
        private dialogRef: MatDialogRef<ProjectExtraStoresAddComponent>,
  ) {
    this.project = this.data.project;
    this.loadData();
   }

  ngOnInit(): void {
  }

  loadData(){
      this.projectService.getExtraSucFiles(this.project.id).subscribe(
        (result:any) => {
          this.isLoading = false;
         this.promotionFiles = result.data;
        },
        (err:any) => {
          this.isLoading = false;
        if(err.error.message != "No valid art files were found in the request")
        {
          Swal.fire({
            icon: 'error',
            title: 'Error al obtener registros',
            text: 'Ocurrió un error al obtener los registros.'
          });
        }
        }
      );
    }
  
    openDialogUploadDistFile() {
      this.matDialog.open(ProjectExtraStoresAddFileComponent, {
        panelClass: ['dialogs-main'],
        data: {
          project: this.project
        }
      }).afterClosed().subscribe(
        resp => {
          if(resp?.reload) {
            this.loadData();
          }
        }
      );
    }

}
