import { Injectable } from '@angular/core';
import { getProjectModel, newProjectModel, ProjectListModel, ProjectCampaignModel, ProjectModel} from '../models/project.model';
import { newCampaignModel, CampaignModel, DashboardCampaigns } from '../models/campaign.model';
import { MainService } from './main.service';
import { map, Observable, tap } from 'rxjs';
import { UsersService } from './users.service';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';
import { CutoffService } from './cutoff.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  current_campaign_id: number = 0;
  current_campaign: any = new ProjectCampaignModel();
  current_project_id: number = 0;
  current_project: any = new ProjectModel();
  promos_file_data: any = [];

  constructor(
    private main: MainService,
    private usrService : UsersService
  ) { }
  areaName : string = '/projects'

  //reglas Medios 
  //1 - reglas
  //2 - prioridad 
  async checkIfSameThenReplace(id_proy: number) {
    return new Promise(async (resolve, reject) => {
      if(id_proy > 0 && id_proy!=this.current_project_id) {
        this.main.ShowLoading();
        if(id_proy) {
          this.getProject(id_proy).subscribe((x: any) => {
            this.main.HideLoading();
            this.current_project = x.data;
            if(this.current_campaign_id == 0 || this.current_campaign_id != this.current_project.id_proy_camp) {
              this.getCampaignProject(this.current_project.id_proy_camp).subscribe((x: any) => {
                this.main.HideLoading();
                this.current_campaign = x.data.find((e:any)=> e.id == this.current_project.id_proy_camp);
                resolve(true);
              },
              (err:any) => {
                console.log(err);
                this.main.HideLoading();
              });
            } 
            else {
              resolve(true);
            }
          },
          (err:any) => {
            console.log(err);
            reject(err);
            this.main.HideLoading();
          });
        }
      }
      else {
        resolve(this.current_project);
      }
    });
  }

  loadCampaign(id_camp: number) {
    this.getCampaignProject(id_camp).subscribe((x: any) => {
      this.main.HideLoading();
      this.current_campaign = x.data.find((e:any)=> e.id == id_camp);
    },
    (err:any) => {
      console.log(err);
      this.main.HideLoading();
    })
  }

  loadPromo(id_camp: number) {
    this.main.ShowLoading();
    this.getPromotionData(id_camp).subscribe((x: any) => {
      this.main.HideLoading();
       return x.data;
    },
    (err:any) => {
      console.log(err);
      this.main.HideLoading();
    })
  }

  getCampaignProjects(id_company : number){
    var get : getProjectModel =  new getProjectModel()
    get.active = 1;
    return this.main.getRequest(this.areaName + "/campaigns/show/" + id_company).pipe(
      map((x:any)=>{
              let objReturn = new ProjectListModel();
              if(x.message == 'Success'){
                  x.data.forEach((e:any) => {
                      let tmpdate = new Date(e.date);
                      tmpdate.setTime(tmpdate.getTime() + 6 * 60 * 60 * 1000);
                      let tmpYear = tmpdate.getFullYear();
                      if(tmpYear != tmpdate.getFullYear()){
                          if(!objReturn.pastyears[tmpYear]){
                              objReturn.pastyears[tmpYear] =  new Array<any>()
                          }
                          objReturn.pastyears[tmpYear].push(e);
                      }else{
                         if(e.status == 1) {
                             objReturn.progress.push(e)
                         } 
                         else {
                             objReturn.finished.push(e)
                         }  
                      }
                  });
              }
              return objReturn;
      }));
  }

  getCampaignProject(campid : number){
    return this.main.getRequest(this.areaName + "/campaigns/" + campid);
  }

  newCampaignProject(newProject : newProjectModel){
    return this.main.postRequest(newProject, this.areaName + "/campaigns/create");
  }

  editCampaignProject(newCampain : newProjectModel){
    return this.main.postRequest(newCampain, this.areaName + "/campaigns/edit");
  }

  deleteCampaignProject(campid : number){
    return this.main.deleteRequest(this.areaName + "/campaigns/delete/" + campid);
  }

  getProjectConfig(id : any){
    return this.main.getRequest("/projects/projects/show/" + id);
  }

  saveProjectVobos(data : any){
    return this.main.postRequest(data,this.areaName + "/vobos/save");
  }

  newProject(newCampaign : newCampaignModel){
    return this.main.postRequest(newCampaign, this.areaName + "/projects/create");
  }

  getProjects(id_camp: number){
    return this.main.getRequest(this.areaName + "/projects/list/" + id_camp);
  }

  editProject(newCampain : newCampaignModel){
    return this.main.postRequest(newCampain, this.areaName + "/projects/edit");
  }

  deleteProject(camp_id : number){
    return this.main.deleteRequest(this.areaName + "/projects/delete/" + camp_id);
  }

  getProject(id_proj : number){
    return this.main.getRequest(this.areaName + "/project/" + id_proj);
  }

  //inventario
  getMedios(id_proj: number){
    return this.main.getRequest(this.areaName + "/medios/" + id_proj);
  }

  createMedio(editMedio : any){
    return this.main.postRequest(editMedio, this.areaName + "/medios/create");
  }

  editMedios(editMedio : any){
    return this.main.postRequest(editMedio, this.areaName + "/medios/edit");
  }
  
  deleteMedios(camp_id : number){
    return this.main.deleteRequest(this.areaName + "/medios/delete/" + camp_id);
  }

  getMedio(id_medio : number){
    return this.main.getRequest(this.areaName + "/medio/" + id_medio);
  }

  getPOPs(id_medio: number){
    return this.main.getRequest(this.areaName + "/elementos-pop/" + id_medio);
  }

  createPOPs(editPop : any){
    return this.main.postRequest(editPop, this.areaName + "/elementos-pop/create");
  }

  editPOPs(editPop : any){
    return this.main.postRequest(editPop, this.areaName + "/elementos-pop/edit");
  }

  deletePOPs(pop_id : number){
    return this.main.deleteRequest(this.areaName + "/elementos-pop/delete/" + pop_id);
  }

  getPOP(id_pop : number){
    return this.main.getRequest(this.areaName + "/elementos-pop/" + id_pop);
  }

  createPOPConfig(editPOPConf : any){
    return this.main.postRequest(editPOPConf, this.areaName + "/elementos-pop/config/create");
  }

  editPOPConfig(editPOPConf : any){
    return this.main.postRequest(editPOPConf, this.areaName + "/elementos-pop/config/edit");
  }

  deleteConfig(id_popconf : number){
    return this.main.getRequest(this.areaName + "/elementos-pop/config/delete/" + id_popconf);
  }

  getPOPConfigs(pop_id: number){
    return this.main.getRequest(this.areaName + "/elementos-pop/config/" + pop_id);
  }

  cloneProjectMedio(id_projecto_origen : number, id_projecto_destino : number){
    return this.main.postRequest({id_proyecto_origen : id_projecto_origen, id_campana_proyecto_destino : id_projecto_destino }, this.areaName + "/proyectos/clone");
  }

  cloneProjectConfig(id_projecto_origen : number, id_projecto_destino : number){
    return this.main.postRequest({id_proyecto_origen : id_projecto_origen, id_campana_proyecto_destino : id_projecto_destino }, this.areaName + "/proyectos/cloneconfig");
  } 

  setCutoffProyect(id_proyecto_campana : number, id_cutoff : number){
    return this.main.postRequest({id_proyecto_campana : id_proyecto_campana, id_cutoff : id_cutoff }, this.areaName + "/proyectos/setcutoff");
  }

  createFormulario(form : any){
    return this.main.postRequest(form, this.areaName + "/proyectos/createformulario");
  }

  getFormularios(){
    return this.main.getRequest(this.areaName + "/proyectos/getformularios/");
  }

  getActiveProjectList(){
    return this.main.getRequest(this.areaName + "/projects/activelist/");
  }


  getConfigData(id : number){ 
    return this.main.getRequest(this.areaName + "/projects/config/" + id);
  }

  //Diagramacion

  getdiagramFiles(data: any) {
    return this.main.postRequest(data, this.areaName + "/diagram/getfiles");
  }

  newProjectDiagram(ins_data : any){
    return this.main.postRequest(ins_data, this.areaName + "/diagram/");
  }
  
  editProjectDiagram(ins_data : any){
    return this.main.postRequest(ins_data, this.areaName + "/diagram/edit/");
  }

  deleteProjectDiagram(id_diagrama : number){
    return this.main.deleteRequest(this.areaName + "/diagram/delete/" + id_diagrama);
  }
  
  getPromotionData(id_camp : number){
    return this.main.getRequest(this.areaName + "/promotions/data/" + id_camp);
  }

  getPromotionFiles(id_proj : number){
    return this.main.getRequest(this.areaName + "/files/promotions/" + id_proj);
  }

  uploadPromotionFile(form : FormData){
    return this.main.postRequestFormData(form, this.areaName + "/files/promotions/");
  }

  getExtraSucFiles(id_proj : number){
    return this.main.getRequest(this.areaName + "/files/extra_stores/" + id_proj);
  }
  
  uploadExtraSucFile(form : FormData){
    return this.main.postRequestFormData(form, this.areaName + "/files/extra_stores/");
  }

  getExtraSucData(id_proj : number){
    return this.main.getRequest(this.areaName + "/data/extra_stores/" + id_proj);
  }

  getProjectsChangelog(id_project : number){
    return this.main.getRequest(this.areaName + "/changelog/" + id_project);
  }

  newProjectsMessage(newMessage : any){
    return this.main.postRequest(newMessage, this.areaName + "/message/");
  }

  getProjectsCampaignsChangelog(id_project : number){
    return this.main.getRequest(this.areaName + "/projects/changelog/" + id_project);
  }

  newProjectsCampaignsMessage(newMessage : any){
    return this.main.postRequest(newMessage, this.areaName + "/projects/message/");
  }

  setProjectPhaseRespVobo(ins_data : any){
    return this.main.postRequest(ins_data, this.areaName + "/diagram/setVobos/");
  }

  resetVoboByProvider(ins_data : any){
    return this.main.postRequest(ins_data, this.areaName + "/diagram/resetVobo/");
  }

  getProjectInvTotals(id : number){
    return this.main.getRequest(this.areaName + "/totalinventory/" + id);
  }
  
  getProjectDistribution(id_proyecto: number, selected_cutoff_id: number){
    return this.main.postRequest({id_proyecto, selected_cutoff_id },this.areaName + "/getdistribucion/");
  }

  getProjectRespVobos(id_diagrama: number, id_proy_vobo: number){
    return this.main.postRequest({id_diagrama, id_proy_vobo },this.areaName + "/getRespuestasVobo/");
  }

  //campaing phased related code
  setPhase(phase_id : number, camp_rol : number, status : number, id_campana_proyecto : number, commentary : string){
    return this.main.postRequest({ phase_id : phase_id, camp_rol : camp_rol, status :  status, id_campana_proyecto : id_campana_proyecto , commentary :  commentary}, this.areaName + "/campaign/setphase/");
  }


  uploadCampaignProjectFile(form : FormData){
    return this.main.postRequestFormData(form, this.areaName + "/files/campaign/");
  }

  getProjectsCampaignsFileChangelog(id_camp_proy_archivo : number){
    return this.main.getRequest(this.areaName + "/projects/file/changelog/" + id_camp_proy_archivo);
  }

  newProjectsCampaignsFileMessage(newMessage : any){
    return this.main.postRequest(newMessage, this.areaName + "/projects/file/message/");
  }

  getProviders(){
    return this.main.getRequest(this.areaName + "/providers/show/");
  }

  setProvidersSurvLock(item : any){
    return this.main.postRequest(item, this.areaName + "/providers/lock/");
  }

  getProvidersList(){
    return this.main.getRequest(this.areaName + "/providers/list/");
  }

  activeProvider(provi : any){
    return this.main.postRequest(provi, this.areaName + "/providers/active");
  }

  getProviderAdminView(provi: any){
    return this.main.getRequest(this.areaName + "/providers/admin/" + provi);
  }

  setProvider(provi : any){
    return this.main.postRequest(provi, this.areaName + "/providers/create");
  }

  editProvider(provi : any){
    return this.main.postRequest(provi, this.areaName + "/providers/edit");
  }

  setProviderMemberCanEdit(provi : any){
    return this.main.postRequest(provi, this.areaName + "/providers/members/setedit");
  }

  setProviderMemberNotify(provi : any){
    return this.main.postRequest(provi, this.areaName + "/providers/members/setnotify");
  }

  deleteProvider(provi : any){
    return this.main.getRequest(this.areaName + "/providers/delete/" + provi);
  }

  hasPermissions(key : string, buho : boolean|any, client : boolean|any):  boolean{
    return this.usrService.getUser().id_rol == environment.role_admin_id || (buho==null&&this.isBuhoUsr()) || (client==null&&this.isClientUsr()) || (((buho?this.isBuhoUsr():false) || (client?this.isClientUsr():false)) && (this.usrService.getUser().permissions.includes(key)));
  }

  uploadCampaignProviderFile(form : FormData){
    return this.main.postRequestFormData(form, this.areaName + "/files/provider/");
  }

  getProvidersSurveys(id_proy_dia : number ){
    return this.main.getRequest(this.areaName + "/providersurveys/" + id_proy_dia);
  }

  deleteProviderSurvey(id : number){
    return this.main.deleteRequest( this.areaName + "/providersurveys/delete/" + id);
  }
  
  saveMember(member: any) {    
    return this.main.postRequest(member, this.areaName +  "/providers/members/create");
  }

  saveResponse(response: any) {    
    return this.main.postRequest(response, this.areaName +  "/providersurveys/response/save");
  }

  getProviderView(view: any) {    
    return this.main.getRequest(this.areaName + "/providersurveys/view/" + view);
  }

  uploadProviderResponseFile(form : FormData){
    return this.main.postRequestFormData(form, this.areaName + "/files/provider_survey_response");
  }

  reuploadCampaignProviderFile(form : FormData){
    return this.main.postRequestFormData(form, this.areaName + "/files/providerreload/");
  }

  resendProviverSurveyEmail(item : any){
    return this.main.postRequest(item, this.areaName +  "/providers/resend/");
  }

  getProviderMemberSignUpView(view: any) {    
    return this.main.getRequest(this.areaName + "/providersignup/view/" + view);
  }

  setProviderMemberPassword(newpass : any){
    return this.main.postRequest(newpass, this.areaName + "/providermember/setpassword/");
  }

  setProviderMemberActive(active : any){
    return this.main.postRequest(active, this.areaName + "/providers/members/active");
  }

  deleteProviderMember(id_provider_member : number){
    return this.main.deleteRequest(this.areaName + "/members/delete/" + id_provider_member);
  }

  getListProjectsProvidersView() {    
    return this.main.getRequest(this.areaName + "/projects/member/show/");
  }

  getFormUrl(id_provider_survey : number){
    return this.main.postRequest({ id_provider_survey : id_provider_survey }, this.areaName + "/getformurl/");
  }

  isBuhoUsr(): boolean{
    return  this.usrService.getUser().id_rol == environment.role_buho ||  this.usrService.getUser().id_rol == environment.role_admin_id;
  }

  getProviverSurveyChangelog(prov_surv : number){
    return this.main.getRequest(this.areaName + "/providersurv/changelog/" + prov_surv);
  }

  getProviverSurveyPreview(prov_surv : number){
    return this.main.getRequest(this.areaName + "/providersurveys/preview/" + prov_surv);
  }

  getProviverMemberInfo(prov_member_id : number){
    return this.main.getRequest(this.areaName + "/providers/members/view/" + prov_member_id);
  }

  editMember(member: any) {    
    return this.main.postRequest(member, this.areaName +  "/providers/members/edit");
  } 

  newProviverSurveyMessage(newMessage : any){
      return this.main.postRequest(newMessage, this.areaName +  "/providersurv/message/");
  }

  //preproyectos
  newPreprojectForm(newPreproject : any){
    return this.main.postRequest(newPreproject, this.areaName +  "/preprojectform/create");
  }

  updatePreprojectForm(newPreproject : any){
    return this.main.postRequest(newPreproject, this.areaName +  "/preprojectform/edit");
  }

  getPreprojectCamp(id : number){
    return this.main.getRequest(this.areaName + "/preprojectform/" + id);
  }

  getPreprojectTotals(id : number){
    return this.main.getRequest(this.areaName + "/preprojecttotals/" + id);
  }
  
  uploadPreprojectTotalFile(form : FormData){
    return this.main.postRequestFormData(form, this.areaName + "/files/preprojectotals/");
  }

  uploadPreprojectFormFile(form : FormData){
    return this.main.postRequestFormData(form, this.areaName + "/files/preprojectform/");
  }

  uploadPreprojectPDFFile(form : FormData){
    return this.main.postRequestFormData(form, this.areaName + "/files/preprojectpdf/");
  }

  getPreprojectPdfs(id : number){
    return this.main.getRequest(this.areaName + "/preprojectpdfs/" + id);
  }

  deletePreprojectForm(id : number){
    return this.main.deleteRequest(this.areaName + "/preprojectform/delete/" + id);
  }

  deletePreprojectPDF(id : number){
    return this.main.deleteRequest(this.areaName + "/preprojectpdfs/delete/" + id);
  }

  generatePreproject1(preproject : any){
    return this.main.postDataUrlExt(preproject, "http://134.209.122.193:8001/preproyecto_auto_nest/" );
  }

  generatePreproject(preproject : any){
    return this.main.postRequest(preproject, this.areaName + "/preprojectform/generate" );
  }

  isClientUsr(): boolean{
    return  this.usrService.getUser().id_rol == environment.role_client;
  }

  getTokenData(token : any){
    return jwt_decode(token);
  }


  //DUMMY DATA
  projectMedia: Array<any> = [
    { id: 1, name: "DANGLER", allowEmptyBranches: false, allowMultipleItems: false, popMaterials: [], segmentable: false, formato : "Reglas" },
    { id: 2, name: "COPETE DE MESA PROMOCIONES", allowEmptyBranches: false, allowMultipleItems: true, popMaterials: [], segmentable: false, formato : "Por Prioridad" },
    { id: 3, name: "BANNER", allowEmptyBranches: false, allowMultipleItems: false, popMaterials: [], segmentable: true, formato : "Reglas" },
    { id: 4, name: "POSTER CHECKOUT", allowEmptyBranches: false, allowMultipleItems: true, popMaterials: [], segmentable: false, formato : "Por Prioridad" }
  ];

  poplistActual : Array<any> = [];

  poplist: Array<any> = [
    { id: 1, media_id: 1, title: 'Dangler', info: [['Posiciones', '-'], ['Prioridad', '-']], filters: [], 
      rules : [
        { pop_id: 1, name: "Dangler", price: 135, cantidad: 1, reglas : []}
      ]},

    { id: 5, media_id: 2, title: 'MDP Trovicel', info: [['Posiciones', '3'], ['Prioridad', '-']], filters: [['Copete Mesa Promocional', 'TROVICEL(RIGIDO)']], 
      rules : [
        { pop_id: 5, name: "MDP Trovicel", price: 135, cantidad: null, reglas : [{
          atributo: "Mesa Promocional",
          comparador: null,
          valor: 1
        }]}
      ]},
    { id: 6, media_id: 2, title: 'MDP Couche', info: [['Posiciones', '3'], ['Prioridad', '-']], filters: [['Copete Mesa Promocional', 'COUCHE']] , 
      rules : [
        { pop_id: 6, name: "MDP Couche", price: 135, cantidad: null, reglas : [{
          atributo: "Mesa Promocional",
          comparador: null,
          valor: 1
        }]}
      ]},
  

    { id: 9,  media_id: 3, title: 'Version 1', info: [['Posiciones', '2'], ['Prioridad', '-']], filters: [] , 
      rules : [
        { pop_id: 9, name: "V1 8 X 60", price: 135, cantidad: null, reglas : [{
          atributo: "8 x 60 (Banner Trad.)",
          comparador: null,
          valor: 1
        }]},
        { pop_id: 9, name: "V1 8 X 80", price: 100, cantidad: null, reglas : [{
          atributo: "8 x 80 (Banner Trad.)",
          comparador: null,
          valor: 1
        }]}
      ]},
    { id: 13, media_id: 4, title: 'Poster Checkout 1', info: [['Posiciones', '-'], ['Prioridad', '1']], filters: [] ,
       rules : [
        { pop_id: 13, name: "Poster Checkout 1", price: 50, cantidad: null, reglas : []}
       ]},
    { id: 14, media_id: 4, title: 'Poster Checkout 2', info: [['Posiciones', '-'], ['Prioridad', '2']], filters: [] , 
      rules : [
        { pop_id: 14, name: "Poster Checkout 2", price: 50, cantidad: null, reglas : []}
      ]},
    { id: 15, media_id: 4, title: 'Poster Checkout 3', info: [['Posiciones', '-'], ['Prioridad', '3']], filters: [] , 
      rules : [
        { pop_id: 15, name: "Poster Checkout 3", price: 50, cantidad: null, reglas : []}
      ]},
  ];
}

export class PreProyecto {
  id! : number;
  id_camp! : number;
  nombre_str!: string;
  cliente!: string;
  nombre!: string;
  folio!: string;
  proyecto!: string;
  contacto!: string;
  tipo!: string;
  area_responsable!: string;
  impresion!: string;
  color!: string;
  tipo_envio!: string;
  recepcion!: string;
  dia_inicio!: number;
  mes_inicio!: string;
  ano_inicio!: number;
  dia_fin!: number;
  mes_fin!: string;
  ano_fin!: number;
  excel_formulario!: string;
  excel_totales!: string;
  pdfs!: string[];
  created_at! : string;
}